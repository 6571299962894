$.lui("part",".part_form_booking",function (selector) {
    $.importScript(cdnjs.airDatepicker,function () {
        $.importScript(cdnjs.airDatepickerLang.replace(".cs.",`.${lang}.`),function () {
            let arrival = selector.find("[data-arrival"),
                departure = selector.find("[data-departure]");

            let calendars = [arrival,departure];

            $.each(calendars, function () {
                let el = $(this),
                    value = el.find(".elm_value"),
                    value_date = value.find("strong"),
                    value_year = value.find("span");

                let dp = el.find("input").datepicker({
                    dateFormat: "yyyy-mm-dd",
                    language: lang,
                    autoClose: true,
                    minDate: new Date(),
                    onShow: function () {
                        value.addClass("state--active");
                        doc.find(".datepickers-container").addClass("state--active");
                    },
                    onHide: function () {
                        value.removeClass("state--active");
                        doc.find(".datepickers-container").removeClass("state--active");
                    },
                    onSelect: function (fd,d,inst) {
                        let day = ("0" + d.getDate()).slice(-2),
                            month = ("0" + (d.getMonth()+1)).slice(-2),
                            year = d.getFullYear();

                        value_date.text(day + "." + month + ".");
                        value_year.text(year);

                        if(el[0] === arrival[0]) {
                            let newDate = new Date(d);
                            newDate.setDate(newDate.getDate() + 1);
                            departure.find("input").data("datepicker").update('minDate', new Date(newDate));
                            departure.find("input").data("datepicker").selectDate(new Date(newDate));
                        }
                    }
                }).data("datepicker");

                value.on("click",function () {
                    dp.show();
                })
            });
            arrival.find("input").data("datepicker").selectDate(new Date);
        });
    });
});