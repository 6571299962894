jQuery.lib_reveal = function() {
    if (!$("html.ie9").length && !$("html.no-sr").length && $("html.sr").length) {

        let reveal_group = [];
        let reveal_element = [];

        let options = {
            delay: 0,
            distance: 0,
            duration: 0,
            scale: 1,
            opacity: null,
            viewFactor: 0.2,
            mobile: true,
            rotate: {
                x: 0,
                y: 0,
                z: 0
            }
        };

        let callbacks = {
            beforeReveal: function (el) {
                $(el).attr("data-lib-reveal", "revealed");
                if($(el).filter("[data-lib-map-load]").length){
                    $(el).data("lib-map-load",true);
                    window.lui.lib["lib_map"]("lib_map",$(el).find("[data-lib-map]"));
                }
            },
            afterReveal: function(el) {
                $(el).removeAttr("style");
            }
        };

        $("[data-lib-reveal-group]").each(function () {
            let d = $(this).data("lib-reveal-group");
            reveal_group.indexOf(d) === -1 && reveal_group.push(d);
        });

        $("[data-lib-reveal]").each(function () {
            let name = $(this).data("lib-reveal");
            if (name !== "single") {
                reveal_element.indexOf(name) === -1 && reveal_element.push(name);
            }
        });

        bodyLoaded(function () {
            ScrollReveal().reveal('[data-lib-reveal="single"]', $.extend(options, callbacks, {interval: null}));

            reveal_group.forEach(function (d) {
                reveal_element.forEach(function (e) {
                    ScrollReveal().reveal(`[data-lib-reveal-group="${d}"] [data-lib-reveal="${e}"]`, $.extend(options, callbacks, {interval: d}));
                });
            });
        });
    } else {
        html.removeClass("sr").addClass("no-sr")
    }
};