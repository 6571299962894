(function($) {
    if (typeof Swup !== "undefined") {
        const swup = new Swup({
            elements: ['#layout_main', '#layout_header']
        });

        swup.on('clickLink', function () {
            $(".nav--visible").removeClass("nav--visible");
            $('html').css('overflow','visible');
            if ($(".lib--dialog").length) {
                nl_lib_dialog.close();
            }
        });

        swup.on('contentReplaced', function () {
            swup.options.elements.forEach((selector) => {
                Object.keys(window.lui.layout).forEach(function(key) {
                    if ($(key).length) {
                        window.lui.layout[key]("layout",key)
                    }
                });
                Object.keys(window.lui.comp).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.comp[key]("comp",key)
                    }
                });
                Object.keys(window.lui.part).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.part[key]("part",key)
                    }
                });
                Object.keys(window.lui.lib).forEach(function(key) {
                    let s = window.lui.lib[key].selector;
                    if ($(selector).find(s).length) {
                        window.lui.lib[key](key,$(selector).find(s))
                    }
                });
            });

            if(typeof window.luiCookieConsentInit !== 'undefined') {
                window.luiCookieConsentInit()
            }

            if (body.hasClass("body--preload")) {
                cssLoaded(function(){
                    body.removeClass("body--preload");
                    setTimeout(function () {
                        body.addClass("body--loaded");
                    }, 300);
                });
            }
        });
    }

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().lui_parallax();
        }
    });

    lui_dialog.init(function(){
        Object.keys(window.lui.lib).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s).length) {
                window.lui.lib[key](key,$(".lib--dialog").find(s))
            }
        });

        Object.keys(window.lui.part).forEach(function(key) {
            if ($(`.lib--dialog ${key}`).length) {
                window.lui.part[key]("part",`.lib--dialog ${key}`)
            }
        });
    });

    $.lui("lib_parallax", ".lib--parallax", function(selector) {
        cssLoaded(function(){
            selector.find("img:not(.lazyload)").parent().lui_parallax();
        });
    });

    $.lui("lib_reveal", "[data-lib-reveal]", function() {
        $.lib_reveal();
    });

    $.lui("lib_ripple", ".part_ui_link, .part_ui_btn, .part_ui_checkbox, .part_ui_radio", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_switch", "[data-lib-tabs]", function(selector) {
        selector.lui_tabs();
    });

    $.lui("lib_map", "[data-lib-map]", function(selector){
        if(selector.closest("[data-lib-map-load]").data("lib-map-load") === true) {
            $.importScript(cdnjs.googlemap.replace("APIKEY", selector.data("lib-map")), function(){
                let zoom = selector.data("zoom");
                let coords = selector.data('coords').split(/[\s,]+/);
                let color = selector.data("color");
                let markers_temp = [],
                    bounds = new google.maps.LatLngBounds();

                let label_elm = selector.closest(".comp_map").find(".wrp_comp_label").find(".part_map_label"),
                    label_name = label_elm.find("[data-name]"),
                    label_address = label_elm.find("[data-address]"),
                    label_email = label_elm.find("[data-email]"),
                    label_phone = label_elm.find("[data-phone]"),
                    label_url = label_elm.find("[data-url]");

                let map = new google.maps.Map(selector[0], {
                    zoom: zoom,
                    center: {
                        lat: parseFloat(coords[0]), lng: parseFloat(coords[1])
                    },
                    mapTypeControl: false,
                    styles: [
                        {
                            "featureType": "all",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "saturation": 36
                                },
                                {
                                    "color": "#333333"
                                },
                                {
                                    "lightness": 40
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 17
                                },
                                {
                                    "weight": 1.2
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dedede"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 29
                                },
                                {
                                    "weight": 0.2
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 18
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f2f2f2"
                                },
                                {
                                    "lightness": 19
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e9e9e9"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        }
                    ]
                });

                let image = {
                    path: 'M 32 1 C 20.413865 1 11 10.413865 11 22 C 11 31.37037 16.116766 41.227882 21.167969 48.804688 C 26.219172 56.381492 31.275391 61.689453 31.275391 61.689453 A 1.0001 1.0001 0 0 0 32.724609 61.689453 C 32.724609 61.689453 37.780828 56.381491 42.832031 48.804688 C 47.883234 41.227882 53 31.37037 53 22 C 53 10.413865 43.586135 1 32 1 z M 32 3 C 42.505255 3 51 11.494745 51 22 C 51 30.62963 46.116766 40.272118 41.167969 47.695312 C 36.799403 54.248162 32.963342 58.445587 32 59.484375 C 31.036658 58.445587 27.200597 54.248162 22.832031 47.695312 C 17.883234 40.272118 13 30.62963 13 22 C 13 11.494745 21.494745 3 32 3 z M 32 13 C 27.041282 13 23 17.041282 23 22 C 23 26.958718 27.041282 31 32 31 C 36.958718 31 41 26.958718 41 22 C 41 17.041282 36.958718 13 32 13 z M 32 15 C 35.877838 15 39 18.122162 39 22 C 39 25.877838 35.877838 29 32 29 C 28.122162 29 25 25.877838 25 22 C 25 18.122162 28.122162 15 32 15 z',
                    fillColor: color,
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    fillOpacity: 1,
                    scale: 0.5,
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(32, 64),
                    labelOrigin: new google.maps.Point(32,90),
                };

                let activeImage = {
                    path: 'm 138.5,69.5 a 69,69 0 0 1 -69,69 69,69 0 0 1 -69,-69 69,69 0 0 1 69,-69 69,69 0 0 1 69,69 z M 75.753,52.077 A 6.2529998,6.2529998 0 0 1 69.5,58.329999 6.2529998,6.2529998 0 0 1 63.247,52.077 6.2529998,6.2529998 0 0 1 69.5,45.824 6.2529998,6.2529998 0 0 1 75.753,52.077 Z M 69.5,38.2 A 13.5,13.5 0 0 0 56,51.7 C 56,63.85 69.5,78.025 69.5,78.025 69.5,78.025 83,63.85 83,51.7 A 13.5,13.5 0 0 0 69.5,38.2 Z',
                    fillColor: color,
                    strokeColor: color,
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    fillOpacity: 0,
                    scale: 1,
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(70, 80),
                    labelOrigin: new google.maps.Point(70,93),
                };

                let map_marker_main = selector.filter("[data-marker]");
                if(map_marker_main.length > 0) {
                    let map_marker = new google.maps.Marker({
                        position: {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])},
                        map: map,
                        icon: image,
                        label: selector.data("marker"),
                    });

                    bounds.extend(new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1])));
                }

                let map_markers = selector.filter("[data-markers]");
                if (map_markers.length > 0) {
                    let markers = selector.data("markers");

                    for (let i = 0; i < markers.length; i++) {
                        let marker = markers[i],
                            coords = marker.coords.split(/[\s,]+/),
                            position = new google.maps.LatLng(coords[0], coords[1]),
                            icon = new google.maps.Marker({
                                position: position,
                                map: map,
                                icon: image,
                                label: marker.title,
                                data: marker.data
                            });

                        markers_temp.push(icon);
                        bounds.extend(position);

                        function showInfo(marker) {
                            return function () {
                                for (let j = 0; j < markers_temp.length; j++) {
                                    markers_temp[j].setIcon(image);
                                }
                                label_elm.removeClass("state--active");
                                marker.setIcon(activeImage);
                                setTimeout(function () {
                                    label_name.html(marker.data.name);
                                    label_address.html(marker.data.address);
                                    label_email.html("<a href=\"mailto:"+marker.data.email+"\" class=\"part_ui_link elm_link\"><span>" + marker.data.email + "</span></a>");
                                    label_phone.html("<a href=\"tel:"+marker.data.phone_url+"\" class=\"part_ui_link elm_link\"><span>" + marker.data.phone + "</span></a>");
                                    label_url.html("<a href=\""+marker.data.booking_url+"\" class=\"part_ui_link type--underline\" data-no-swup><span>" + label_url.data("url") + "</span></a>");
                                    label_elm.addClass("state--active");
                                },500);
                            }
                        }

                        google.maps.event.addListener(icon, 'click', showInfo(icon));
                    }
                    new google.maps.event.trigger(markers_temp[0], 'click');
                    if(win.width() < 1440) {
                        map.fitBounds(bounds);
                    }
                }
            });
        }
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let $el = $(this);

            $.ajax({
                url: $el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                });
            });
        });
    })();

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();
})(jQuery);
