$.lui("layout","#layout_header", function(selector) {
    let header = selector.find(".wrp_header_body"),
        nav = doc.find("#layout_nav");

    doc.on("click","[data-menu-toggle]",function () {
        $(this).toggleClass("nav--visible");
        doc.find("#layout_header").toggleClass("nav--visible");
        nav.toggleClass("nav--visible");
    });

    $.importScript(cdnjs.headroom, function(){
        let headroom = new Headroom(selector[0], {
            offset: 5,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    });
});