$.lui("comp",".comp_detail",function (selector) {
    let gallery = selector.find("[data-lib-lightgallery]");
    if(gallery.length) {
        $.importScript(cdnjs.lightgallery, function(){
            gallery.lightGallery({
                thumbnail: true,
                selector: ".lib--lightgallery-item",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: false,
                download: true,
                autoplay: false,
                autoplayControls: false,
            }).data("lightGallery");
        });
    }
});