$.lui("part",".part_ui_count",function (selector) {
    selector.on("click","[data-click]",function () {
        let el = $(this);
        let inp = el.closest(selector).find("input");
        let newVal = 0;
        let step = parseInt(inp.prop("step"));

        if(el.data("click") === "number-minus") {
            newVal = +inp.val() - step;
        }
        if(el.data("click") === "number-plus") {
            newVal = +inp.val() + step;
        }
        if(newVal <= parseInt(inp.prop("max")) && newVal >= parseInt(inp.prop("min"))) {
            inp.val(newVal).trigger("change");
        }
    })
});