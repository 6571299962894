(function($) {
    if (!html.hasClass("ie9")) {
        doc.on("change", ".part_ui_input", function () {
            if ($(this).hasClass("type--number")) {
                if ($(this).find("input").val() === "" || parseInt($(this).find("input").val()) > $(this).find("input").attr("max")) {
                    $(this).find("input").val(1);
                }
            }
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validateInput();
            }
        });

        doc.on("click", `.part_ui_input [data-click^="number"]`, function (e) {
            e.preventDefault();
            let elm = $(this);
            let inp = elm.closest(".part_ui_input").find("input");
            let num;

            if (elm.filter('[data-click="number-plus"]').length) {
                num = +inp.val() + inp.data('step');
                if (num <= inp.attr('max')) {
                    inp.val(num).trigger("change");
                }
            }
            if (elm.filter('[data-click="number-minus"]').length) {
                num = +inp.val() - inp.data('step');
                if (num >= inp.attr('min')) {
                    inp.val(num).trigger("change");
                }
            }
        });

        doc.on("keydown", ".part_ui_input.type--number input, .part_ui_count input", function(e) {
            // Allow: backspace, delete, tab, escape, enter and .
            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 || e.keyCode === 16 || (e.keyCode == 65 && e.ctrlKey === true) || (e.keyCode >= 35 && e.keyCode <= 40)) {
                return;
            }
            // Ensure that it is a number and stop the keypress
            if (((e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });

        $.lui("lib_input", ".part_ui_input", function(selector) {
            selector.each(function () {
                if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("state--invalid")) {
                    $(this).validateInput();
                }
            });
        });
    }
})(jQuery);